import React, { useEffect, useState, useMemo } from "react";
import styles from "./LocationMap.module.sass";
import SriLankaMaps from "../SVGMap"; // Import your SVG map component
import WorldMap from "../SVGMap/worldmap"; // You need to create a WorldMap component or use an existing library
import api from "../../api";

const LocationMap = ({ sriLankaProvinces = [], worldCountries = [] }) => {
  const [activeTab, setActiveTab] = useState("SriLanka");

  // Hash function to generate consistent colors
  const getHashColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `#${((hash >> 24) & 0xFF).toString(16).padStart(2, '0')}${((hash >> 16) & 0xFF).toString(16).padStart(2, '0')}${((hash >> 8) & 0xFF).toString(16).padStart(2, '0')}`;
    return color;
  };

  // Create a consistent color mapping
  const colorMapping = useMemo(() => {
    const mapping = {};
    const data = activeTab === "World" ? worldCountries : sriLankaProvinces;
    data.forEach((item) => {
      mapping[item.name] = getHashColor(item.name);
    });
    return mapping;
  }, [activeTab, worldCountries, sriLankaProvinces]);

  // Render map based on the selected tab
  const renderMap = () => {
    if (activeTab === "World") {
      return <WorldMap colorMapping={colorMapping} />;
    }
    return <SriLankaMaps colorMapping={colorMapping} />;
  };

  // Render legend based on the selected tab
  const renderLegend = () => {
    const data = activeTab === "World" ? worldCountries : sriLankaProvinces;
    return (
      <ul>
        {data.map((item, index) => (
          <li key={index}>
            <span
              className={activeTab === "World" ? styles.country : styles[item.name.toLowerCase()]}
              style={{ backgroundColor: colorMapping[item.name] }}
            ></span>
            {item.name} - {item.value}%
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>
          {activeTab === "World" ? "World Map" : "Sri Lanka Map"}
        </h2>
        <div className={styles.tabs}>
          {/* Tab toggle UI */}
          <button
            className={`${styles.tabButton} ${activeTab === "SriLanka" ? styles.active : ""}`}
            onClick={() => setActiveTab("SriLanka")}
          >
            Sri Lanka Map
          </button>
          <button
            className={`${styles.tabButton} ${activeTab === "World" ? styles.active : ""}`}
            onClick={() => setActiveTab("World")}
          >
            World Map
          </button>
        </div>
      </div>
      <div className={styles.mapWrapper}>
        <div className={styles.map}>{renderMap()}</div>
        <div className={styles.legend}>
          <h3>{activeTab === "World" ? "Countries" : "Lead by Provinces"}</h3>
          {renderLegend()}
        </div>
      </div>
    </div>
  );
};

// Using the component with the sample data
export default function App() {
  const [sampleWorldCountries, setSampleWorldCountries] = useState([]);
  const [sampleSriLankaProvinces, setSampleSriLankaProvinces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [worldResponse, sriLankaResponse] = await Promise.all([
          api.get("/scans/countries/top"),
          api.get("/scans/sri-lanka/top"),
        ]);
        setSampleWorldCountries(worldResponse.data);
        setSampleSriLankaProvinces(sriLankaResponse.data);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Loading state
  }

  if (error) {
    return <div>{error}</div>; // Error handling
  }

  return (
    <LocationMap
      sriLankaProvinces={sampleSriLankaProvinces}
      worldCountries={sampleWorldCountries}
    />
  );
}
