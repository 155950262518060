import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import Icon from "../Icon";
import User from "./User"; // Import the User component that handles avatar and logout

const Header = ({ onLogout }) => {
  const [visible, setVisible] = useState(false);
  const handleClick = () => {
    setVisible(false);
  };

  return (
    <header className={styles.header}>
      <button className={styles.burger} onClick={handleClick}></button>
      <button
        className={cn(styles.buttonSearch, { [styles.active]: visible })}
        onClick={() => setVisible(!visible)}
      >
        <Icon name="search" size="24" />
      </button>
      <div className={styles.control} onClick={handleClick}>
        {/* User Avatar and Logout Functionality */}
        <User className={styles.user} />
      </div>
    </header>
  );
};

export default Header;
